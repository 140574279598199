import axios, { AxiosError, AxiosResponse } from 'axios'

import { IRequestOptions, IResponse } from '../types/common'

const request = ({
                   options = {
                     url: '/',
                     method: 'get',
                   },
                   headers = {},
                 }: IRequestOptions): IResponse | Promise<IResponse> => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_PROD_SERVER_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType: 'json',
  })

  const onSuccess = (response: AxiosResponse) => {
    return response.data
  }

  const onError = async (error: AxiosError) => {
    return error.response
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
