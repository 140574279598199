import request from '.'
import { IAxiosInstance } from '../types/common'

export const exportData = (
  user_id: string,
): IAxiosInstance | Promise<IAxiosInstance> => {
  return request({
    options: {
      url: `/data/${user_id}/export`,
      method: 'get',
    },
  })
}

export const clearData = (
  user_id: string,
): IAxiosInstance | Promise<IAxiosInstance> => {
  return request({
    options: {
      url: `/data/${user_id}/clear`,
      method: 'get',
    },
  })
}
