import { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

import { getGoogleId, dataflowTracker } from '@pixel-portal/src/dataflow_tracker';
import { exportData, clearData } from '../../api/Data.api'
import { ExportToCsv } from 'export-to-csv';

import DataLysmIcon from '../../assets/images/datalysm.svg';
import './home.css'

const Home = (): ReactElement => {
  const downloadData = useRef(null);
  const removeData = useRef(null);
  const [userId, setUserId] = useState('');
  const [optText, setOptText] = useState('');
  const [isOptButtonClick, setOptButtonClick] = useState(false);

  const [msgOnOptError, setMsgOnOptError] = useState('');
  const [msgOnDownloadError, setMsgOnDownloadError] = useState('');
  const [msgOnRemoveError, setMsgOnRemoveError] = useState('');

  useEffect(() => {
    setUserId(getGoogleId().toString());
    setOptText('Opt Out');

    const isOptButtonClickEvent = Cookies.get('isOptButtonClickEvent');
    if (isOptButtonClickEvent == undefined && isOptButtonClick == false) {
      dataflowTracker('pixel-portal','web');
      setUserId(getGoogleId().toString());

      downloadData.current.className = 'btn btn-opt-in';
      removeData.current.className = 'btn btn-opt-in';
    }
    if (isOptButtonClickEvent == 'true') {
      setOptText('Opt In');
      setOptButtonClick(true);

      downloadData.current.className = 'btn btn-opt-in disabled';
      removeData.current.className = 'btn btn-opt-in disabled';
    }
  });

  function useInterval(callback, delay) {
    const savedCallback = useRef();
    savedCallback.current = callback;

    function tick() { savedCallback.current(); }
    // Set up the interval.
    useEffect(() => {
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  function getInterval(message) {
    const delay = 3500;
    switch(message) {
      case 'opt':
        useInterval(() => {
          setMsgOnOptError('');
        }, delay);
        return <h3 className='msgError'>{msgOnOptError}</h3>;
      case 'download':
        useInterval(() => {
          setMsgOnDownloadError('');
        }, delay);
        return <h3 className='msgError'>{msgOnDownloadError}</h3>;
      case 'remove':
        useInterval(() => {
          setMsgOnRemoveError('');
          Cookies.remove('dataflow_id.5ed0');
        }, delay);
        return <h3 className='msgError'>{msgOnRemoveError}</h3>;
      default:
        return <h3 className='msgError'></h3>;
    }
  }

  const handleOptClick = async () => {
    try {
      if (isOptButtonClick == false) {
        setOptText('Opt In');
        setOptButtonClick(true);
        setMsgOnOptError('Your browsing data from this device would not be collected');
        Cookies.set('isOptButtonClickEvent', true);

        // tracking off
        window.snowplow('setOptOutCookie', 'opt-out');
        Cookies.set('setOptOutCookie', 'opt-out');
      } else {
        setOptText('Opt Out');
        setOptButtonClick(false);
        setMsgOnOptError('Your browsing data from this device will be collected now');
        Cookies.remove('isOptButtonClickEvent');
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const handleUploadData = async () => {
    try {
      if (userId != 'unknown' && downloadData.current.className == 'btn btn-opt-in') {
        const result = Array(await exportData(userId));

        if (result[0].status == 404) {
          setMsgOnDownloadError('There is no data to be downloaded');
        } else {
          const options = {
            fieldSeparator: '',
            quoteStrings: '',
            decimalSeparator: '',
            showLabels: true,
            showTitle: false,
            title: 'Data',
            filename: 'snowplow_data',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
          };

          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(result);
        }
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const handleClearData = async () => {
    try {
      if (userId != 'unknown' && removeData.current.className == 'btn btn-opt-in') {
        setMsgOnRemoveError('Your navigation data had been cleared');
        await clearData(userId);

        setOptText('Opt In');
        setOptButtonClick(true);
        Cookies.set('isOptButtonClickEvent', true);
        downloadData.current.className = 'btn btn-opt-in disabled';

        // tracking off
        window.snowplow('setOptOutCookie', 'opt-out');
        Cookies.set('setOptOutCookie', 'opt-out');
      }
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <Fragment>
      <img className="section-title" src={DataLysmIcon} width='280px' height='auto' alt='DataLysm Icon' />
      <section className="page-section page-section--dark text-center">
        <div className="container section">
          <h4 className="section-title1">Request exclusion from data collection</h4>
          <p className="small">If you prefer to avoid that we collect browsing data from this device,
            you can simply click on the <strong>OPT OUT</strong> link below. In this way your
            navigation device will be identified by <strong>cookies</strong> anonymously and no information will be stored.
            We remind you that if the <strong>dataflow_id.5ed0 and dataflow_ses.5ed0 cookie</strong> is
            deleted we will no longer be able to recognize your device, therefore the data collection within the sites participating in this initiative
            will start again.</p>
          <button onClick={handleOptClick} className="btn btn-opt-in" id="optinout">{optText}</button>

          {getInterval('opt')}

          <h4 className="section-title2">Download my data</h4>
          <p className="small">In compliance with Regulation (EU) 2016/679, Section 3, Article 20, the interested party
            has the right to receive in an electronic format the personal data concerning him provided to the data controller.
            If you want to download the data collected so far through your cookie,
            you can simply click on the <strong>DOWNLOAD DATA</strong> link below.</p>
          <button ref={downloadData} onClick={handleUploadData} id="portability">Data download</button>

          {getInterval('download')}

          <h4 className="section-title3">Request for oblivion</h4>
          <p className="small">In compliance with Regulation (EU) 2016/679, Section 3, Article 17, the interested party has the right to obtain
            the cancellation of personal data concerning him provided to the data controller. If you prefer to delete the
            navigation data collected so far from this device, you can simply click on the <strong>REMOVE DATA</strong> link below.
            All data relating to your account will be deleted within 7 days. Furthermore, by clicking on the <strong>REMOVE DATA</strong> button,
            we will activate the <strong>OPT-OUT</strong> procedure, thus stopping the collection of navigation data from this device.</p>
          <button ref={removeData} onClick={handleClearData} id="dimenticami">Remove Data</button>

          {getInterval('remove')}
        </div>
      </section>
    </Fragment>
  )
}

export default Home
